import http from "./http";

export const actions = {
  PERFILES_VER: 100,
  PERFILES_CREAR: 101,
  PERFILES_EDITAR: 102,
  PERFILES_ELIMINAR: 103,

  USUARIOS_VER: 200,
  USUARIOS_CREAR: 201,
  USUARIOS_EDITAR: 202,
  USUARIOS_ELIMINAR: 203,

  AUDITORIA_VER: 304,

  PARAMETROS_VER: 400,
  PARAMETROS_CREAR: 401,
  PARAMETROS_EDITAR: 402,
  PARAMETROS_ELIMINAR: 403,

  MASTERDOC_VER: 500,
  MASTERDOC_CREAR: 501,
  MASTERDOC_EDITAR: 502,
  MASTERDOC_ELIMINAR: 503,

  EXPEDIENTE_VER: 600,
  EXPEDIENTE_CREAR: 601,
  EXPEDIENTE_EDITAR: 602,
  EXPEDIENTE_ELIMINAR: 603,
  EXPEDIENTE_ARCHIVO_AGREGAR: 604,
  EXPEDIENTE_ARCHIVO_ELIMINAR: 605,
  EXPEDIENTE_DOCUMENTO_AGREGAR: 606,
  EXPEDIENTE_DOCUMENTO_ELIMINAR: 607,
  EXPEDIENTE_VER_SENSIBLE: 608,
  EXPEDIENTE_ARCHIVO_VER: 609,

  LEGAJO_VER: 700,
  LEGAJO_CREAR: 701,
  LEGAJO_EDITAR: 702,
  LEGAJO_ELIMINAR: 703,
  LEGAJO_ARCHIVO_AGREGAR: 704,
  LEGAJO_ARCHIVO_ELIMINAR: 705,
  LEGAJO_DOCUMENTO_AGREGAR: 706,
  LEGAJO_DOCUMENTO_ELIMINAR: 707,
  LEGAJO_VER_SENSIBLE: 708,
  LEGAJO_ARCHIVO_VER: 709,

  DIRECCIONES_VER: 800,
  DIRECCIONES_CREAR: 801,
  DIRECCIONES_EDITAR: 802,
  DIRECCIONES_ELIMINAR: 803,

  CAJA_DIARIA_VER: 900,
  CAJA_DIARIA_CREAR: 901,
  CAJA_DIARIA_EDITAR: 902,
  CAJA_DIARIA_ELIMINAR: 903,
  CAJA_DIARIA_ARCHIVO_AGREGAR: 904,
  CAJA_DIARIA_ARCHIVO_ELIMINAR: 905,
  CAJA_DIARIA_DOCUMENTO_AGREGAR: 906,
  CAJA_DIARIA_DOCUMENTO_ELIMINAR: 907,
  CAJA_DIARIA_VER_SENSIBLE: 908,
  CAJA_DIARIA_ARCHIVO_VER: 909,

  SISTEMAS_VER: 1000,
  SISTEMAS_CREAR: 1001,
  SISTEMAS_EDITAR: 1002,
  SISTEMAS_ELIMINAR: 1003,
  SISTEMAS_ARCHIVO_AGREGAR: 1004,
  SISTEMAS_ARCHIVO_ELIMINAR: 1005,
  SISTEMAS_DOCUMENTO_AGREGAR: 1006,
  SISTEMAS_DOCUMENTO_ELIMINAR: 1007,
  SISTEMAS_VER_SENSIBLE: 1008,
  SISTEMAS_ARCHIVO_VER: 1009,

  DIGESTO_VER: 1100,
  DIGESTO_CREAR: 1101,
  DIGESTO_EDITAR: 1102,
  DIGESTO_ELIMINAR: 1103,
  DIGESTO_ARCHIVO_AGREGAR: 1104,
  DIGESTO_ARCHIVO_ELIMINAR: 1105,
  DIGESTO_DOCUMENTO_AGREGAR: 1106,
  DIGESTO_DOCUMENTO_ELIMINAR: 1107,
  DIGESTO_VER_SENSIBLE: 1108,
  DIGESTO_ARCHIVO_VER: 1109,

  RENDICIONES_VER: 1200,
  RENDICIONES_CREAR: 1201,
  RENDICIONES_EDITAR: 1202,
  RENDICIONES_ELIMINAR: 1203,
  RENDICIONES_ARCHIVO_AGREGAR: 1204,
  RENDICIONES_ARCHIVO_ELIMINAR: 1205,
  RENDICIONES_DOCUMENTO_AGREGAR: 1206,
  RENDICIONES_DOCUMENTO_ELIMINAR: 1207,
  RENDICIONES_VER_SENSIBLE: 1208,
  RENDICIONES_ARCHIVO_VER: 1209,
};

export const setData = (data) => {
  localStorage.setItem("token", data.token);
  localStorage.setItem("user", JSON.stringify(data.user));
};

export const getUser = () => {
  const user = localStorage.getItem("user");
  return user === null ? { nombre: "", sexo: "" } : JSON.parse(user);
};

export const getJwt = () => {
  return localStorage.getItem("token");
};

export const validateToken = async () => {
  const result = await http.post("auth/validate", {});
  return result && result.data.code === 0;
};

export const hasAction = (action) => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    const permisos = JSON.parse(atob(user.permisos));
    return permisos.indexOf(action) > -1;
  } catch (error) {
    return false;
  }
};
