import React from "react";
import { NavLink } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import {
  FiHome,
  FiShield,
  FiUser,
  FiClock,
  FiSettings,
  FiBookmark,
  FiFileText,
  FiFolder,
  FiX,
} from "react-icons/fi";
import { hasAction, actions } from "./../services/security";
import logo from "./../assets/images/logo.png";
import "./SideBar.scss";

const SideBar = ({ collapsed, onCollapse }) => {
  const getSize = () => {
    return collapsed
      ? { width: "80px", height: "calc(100% - 74px)" }
      : { width: "260px", height: "calc(100% - 74px)" };
  };

  const getOverlay = () => {
    return collapsed ? "sidebar-overlay" : "sidebar-overlay visible";
  };

  const getClasses = () => {
    return collapsed ? "sidebar collapsed" : "sidebar";
  };

  return (
    <div className={getOverlay()}>
      <div className={getClasses()}>
        <div className="sidebar-header">
          <img className="sidebar-logo" src={logo} alt="logo" />
          <p className="sidebar-title">
            <span className="small"></span>
            <span className="name">Municipalidad</span>
          </p>
          <FiX className="sidebar-collapse" onClick={() => onCollapse(true)} />
        </div>
        <Scrollbars className="sidebar-scroll" style={getSize()} autoHide>
          <ul className="sidebar-list">
            <li className="nav-item">
              <NavLink to="/" exact>
                <FiHome />
                <div>Inicio</div>
              </NavLink>
            </li>
            {hasAction(actions.EXPEDIENTE_VER) && (
              <li className="nav-item">
                <NavLink to="/expediente">
                  <FiFileText />
                  <div>Expedientes</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.LEGAJO_VER) && (
              <li className="nav-item">
                <NavLink to="/legajo">
                  <FiFileText />
                  <div>Legajos</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.CAJA_DIARIA_VER) && (
              <li className="nav-item">
                <NavLink to="/caja-diaria">
                  <FiFileText />
                  <div>Caja Diaria</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.SISTEMAS_VER) && (
              <li className="nav-item">
                <NavLink to="/sistemas">
                  <FiFileText />
                  <div>Sistemas</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.DIGESTO_VER) && (
              <li className="nav-item">
                <NavLink to="/digesto">
                  <FiFileText />
                  <div>Digesto</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.RENDICIONES_VER) && (
              <li className="nav-item">
                <NavLink to="/rendiciones">
                  <FiFileText />
                  <div>Rendiciones</div>
                </NavLink>
              </li>
            )}

            {(hasAction(actions.PARAMETROS_VER) ||
              hasAction(actions.MASTERDOC_VER) ||
              hasAction(actions.DIRECCIONES_VER)) && (
                <li className="nav-header">Configuración</li>
              )}
            {hasAction(actions.PARAMETROS_VER) && (
              <li className="nav-item">
                <NavLink to="/configuracion/parametros">
                  <FiSettings />
                  <div>Parámetros</div>
                </NavLink>
              </li>
            )}

            {hasAction(actions.MASTERDOC_VER) && (
              <li className="nav-item">
                <NavLink to="/configuracion/masterdoc">
                  <FiBookmark />
                  <div>Documentos</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.DIRECCIONES_VER) && (
              <li className="nav-item">
                <NavLink to="/configuracion/direcciones">
                  <FiFolder />
                  <div>Direcciones</div>
                </NavLink>
              </li>
            )}

            {(hasAction(actions.PERFILES_VER) ||
              hasAction(actions.USUARIOS_VER) ||
              hasAction(actions.AUDITORIA_VER)) && (
                <li className="nav-header">Seguridad</li>
              )}
            {hasAction(actions.PERFILES_VER) && (
              <li className="nav-item">
                <NavLink to="/seguridad/perfiles">
                  <FiShield />
                  <div>Perfiles</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.USUARIOS_VER) && (
              <li className="nav-item">
                <NavLink to="/seguridad/usuarios">
                  <FiUser />
                  <div>Usuarios</div>
                </NavLink>
              </li>
            )}
            {hasAction(actions.AUDITORIA_VER) && (
              <li className="nav-item">
                <NavLink to="/seguridad/auditoria">
                  <FiClock />
                  <div>Auditoría</div>
                </NavLink>
              </li>
            )}
          </ul>
        </Scrollbars>
      </div>
    </div>
  );
};

export default SideBar;
