import { HashRouter as Router, Route } from "react-router-dom";
import { AnimatedSwitch } from 'react-router-transition';

import Master from './views/Master';
import Login from './views/Login/Login';

import './App.scss';
import './components/Buttons.scss';
import './components/Cards.scss';
import './components/DatePicker.scss';
import './components/Dropdowns.scss';
import './components/Fonts.scss';
import './components/Forms.scss';
import './components/Inputs.scss';
import './components/Labels.scss';
import './components/Modals.scss';
import './components/Notifications.scss';
import './components/Pagination.scss';
import './components/Radio.scss';
import './components/Selects.scss';
import './components/Switch.scss';
import './components/Tags.scss';

function App() {
  return (
    <Router>
      <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper"
        >
        <Route path="/login" component={Login} />
        <Route path="/" component={Master} />
      </AnimatedSwitch>
    </Router>
  );
}

export default App;
