/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Input, Button, Row, Col } from "antd";
import { FiClock } from "react-icons/fi";
import ReactJson from "react-json-view";

import http from "./../../../services/http";
import { hasAction, actions } from "./../../../services/security";

import Header from "./../../../components/Header";

import "./AuditoriaForm.scss";

const AuditoriaForm = ({ match }) => {
  const [form] = Form.useForm();

  const VER = hasAction(actions.AUDITORIA_VER);

  const [state] = useState({
    title: "Ver",
    id: parseInt(match.params.id),
  });
  const [contenido, setContenido] = useState({});

  const breadcrumb = [
    { id: 1, title: "Seguridad" },
    { id: 2, title: "Auditoría", url: "/seguridad/auditoria" },
    { id: 3, title: state.title },
  ];

  useEffect(() => {
    async function getForm() {
      const rForm = await http.get(`auditoria/${state.id}`);
      if (rForm && rForm.data.code === 0) {
        const data = rForm.data.data;
        setContenido(data.contenido !== "" ? JSON.parse(data.contenido) : {});
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="auditoria">
      <Header title={`${state.title} Auditoría`} breadcrumb={breadcrumb} />

      <Form form={form} layout="vertical">
        <div className="form card">
          <div className="form-icon">
            <FiClock />
          </div>

          <Row gutter="20">
            <Col span="12">
              <Form.Item label="Fecha" name="fecha">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item label="Hora" name="hora">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Usuario" name="usuario">
            <Input disabled />
          </Form.Item>

          <Form.Item label="Accion" name="accion">
            <Input disabled />
          </Form.Item>

          <Form.Item label="Identificador" name="identificador">
            <Input disabled />
          </Form.Item>

          <Form.Item label="Contenido">
            <ReactJson
              name={null}
              src={contenido}
              displayDataTypes={false}
              enableClipboard={false}
            />
          </Form.Item>

          <div className="form-actions">
            <Link to="/seguridad/auditoria">
              <Button type="secondary">Volver</Button>
            </Link>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AuditoriaForm;
