/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Button, Form, Input, Menu, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FiEdit, FiPlus, FiTrash2 } from 'react-icons/fi';

import { ContextProvider } from '../../services/context';
import http from '../../services/http';
import { showSuccess } from '../../services/notification';
import { hasAction, actions } from '../../services/security';

import Header from '../../components/Header';
import Filters from '../../components/Filters';
import FiltersButton from '../../components/FiltersButton';
import Table from '../../components/Table';

const Digesto = () => {
  const history = useHistory();

  const VER = hasAction(actions.DIGESTO_VER);
  const CREAR = hasAction(actions.DIGESTO_CREAR);
  const EDITAR = hasAction(actions.DIGESTO_EDITAR);
  const ELIMINAR = hasAction(actions.DIGESTO_ELIMINAR);

  const [state, setState] = useState({
    filters: {
      visible: false,
      numero: '',
      descripcion: '',
    },
    columns: [
      {
        id: 1,
        title: 'Número',
        style: {},
        render: (item) => item.numero,
      },
      {
        id: 2,
        title: 'Dirección',
        style: {},
        render: (item) => item.direccion,
      },
      {
        id: 3,
        title: 'Descripción',
        style: {},
        render: (item) => item.descripcion,
      },
      {
        id: 4,
        title: 'Comentario',
        style: {},
        render: (item) => item.comentario,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });

  const breadcrumb = [{ id: 1, title: 'Digesto', url: '/digesto' }];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
        {CREAR && (
          <Button type="primary" icon={<FiPlus />} onClick={onAdd}>
            Nuevo
          </Button>
        )}
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Número" name="numero">
          <Input />
        </Form.Item>
        <Form.Item label="Descripción" name="descripcion">
          <Input />
        </Form.Item>
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {(EDITAR || VER) && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Ver / Editar
          </Menu.Item>
        )}
        {ELIMINAR && (
          <Menu.Item key="1" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onAdd = () => {
    history.push('/digesto/nuevo');
  };

  const onEdit = (item) => {
    history.push(`/digesto/${item.id}`);
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: 'Confirmar eliminación',
      icon: <ExclamationCircleOutlined />,
      content: '¿Esta seguro que desea eliminar este registro de digesto?',
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk: async () => {
        const rDelete = await http.delete(`digesto/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess('El registro fue eliminado exitosamente.');
          setState({ ...state, total: 0 });
        }
      },
    });
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="digesto page">
      <ContextProvider value={[state, setState]}>
        <Header title="Digesto" breadcrumb={breadcrumb} buttons={buttons} />
        <Filters filters={filters} />
        <Table url="digesto/list" menu={menu} />
      </ContextProvider>
    </div>
  );
};

export default Digesto;
