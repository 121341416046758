/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Form, Input, Menu, Select } from "antd";
import { FiEdit } from "react-icons/fi";

import { ContextProvider } from "./../../../services/context";
import http from "./../../../services/http";
import { hasAction, actions } from "./../../../services/security";

import Header from "./../../../components/Header";
import Filters from "./../../../components/Filters";
import FiltersButton from "./../../../components/FiltersButton";
import Table from "./../../../components/Table";

const { Option } = Select;

const Parametros = () => {
  const history = useHistory();

  const VER = hasAction(actions.PARAMETROS_VER);
  const EDITAR = hasAction(actions.PARAMETROS_EDITAR);

  const [state, setState] = useState({
    filters: {
      visible: false,
      grupo: "",
      nombre: "",
    },
    columns: [
      {
        id: 1,
        title: "Grupo",
        style: {},
        render: (item) => item.grupo,
      },
      {
        id: 2,
        title: "Nombre",
        style: {},
        render: (item) => item.nombre,
      },
      {
        id: 3,
        title: "Valor",
        style: {},
        render: (item) => item.valor,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });
  const [grupos, setGrupos] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await http.get("parametros/data");
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setGrupos(data.grupos);
      }
    }

    if (VER) {
      getData();
    }
  }, []);

  const breadcrumb = [
    { id: 1, title: "Configuración" },
    { id: 2, title: "Parámetros", url: "/configuracion/parametros" },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Grupo" name="grupo">
          <Select allowClear>
            {grupos.map((x) => (
              <Option key={x.value} value={x.value}>
                {x.text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Nombre" name="nombre">
          <Input />
        </Form.Item>
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {EDITAR && (
          <Menu.Item key="0" onClick={() => onEdit(item)}>
            <FiEdit />
            Editar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onEdit = (item) => {
    history.push(`/configuracion/parametros/${item.id}`);
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="perfiles page">
      <ContextProvider value={[state, setState]}>
        <Header title="Parámetros" breadcrumb={breadcrumb} buttons={buttons} />
        <Filters filters={filters} />
        <Table url="parametros/list" menu={menu} />
      </ContextProvider>
    </div>
  );
};

export default Parametros;
