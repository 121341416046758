import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { FiChevronsRight, FiHome } from "react-icons/fi";
import "./Header.scss";

const Header = ({ title, breadcrumb, buttons }) => {
  return (
    <div className="header">
      <div className="header-breadcrumb">
        <Breadcrumb separator={<FiChevronsRight />}>
          <Breadcrumb.Item>
            <Link to="/">
              <FiHome />
            </Link>
          </Breadcrumb.Item>
          {breadcrumb.map((level) => (
            <Breadcrumb.Item key={level.id}>
              {level.url === undefined && level.title}
              {level.url !== undefined && (
                <Link to={level.url}>{level.title}</Link>
              )}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>

      <div className="header-info">
        <div className="header-title">{title}</div>
        <div className="header-actions">{buttons && buttons()}</div>
      </div>
    </div>
  );
};

export default Header;
