/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Drawer, Form } from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import { parseToDate } from "./../services/forms";
import { Context } from "./../services/context";
import "./Filters.scss";

const Filters = ({ filters, initial }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const [state, setState] = useContext(Context);

  useEffect(() => {
    const serialized = localStorage.getItem(history.location.pathname);
    if (serialized) {
      const table = JSON.parse(serialized);
      setState({
        ...state,
        filters: table.filters,
        page: table.page,
        size: table.size,
        loaded: true,
      });
    } else {
      setState({ ...state, loaded: true });
    }
  }, []);

  useEffect(() => {
    if (!state.filters.visible) {
      return;
    }

    const regexDate = new RegExp("^[0-9]{2}[/][0-9]{2}[/][0-9]{4}$");

    let nFilters = { ...state.filters };

    for (const property in nFilters) {
      const value = nFilters[property];
      if (regexDate.test(value)) {
        nFilters[property] = parseToDate(value);
      }
    }

    form.setFieldsValue(nFilters);
  }, [state.filters.visible]);

  const close = () => {
    let nState = { ...state };
    nState.filters.visible = false;
    setState(nState);
  };

  const reset = () => {
    form.resetFields();
  };

  const onSave = () => {
    const values = form.getFieldsValue();

    let nState = { ...state, reload: !state.reload };
    for (const property in values) {
      if (values[property] && typeof values[property] === "object") {
        values[property] = values[property].format("DD/MM/YYYY");
      }

      nState.filters[property] = values[property]
        ? values[property].toString()
        : "";
    }
    nState.filters.visible = false;

    setState(nState);
  };

  const onEnter = (e) => {
    if (e.key === "Enter") {
      onSave();
    }
  };

  return (
    <Drawer
      className="filters"
      title="Filtros"
      placement="right"
      onClose={close}
      visible={state.filters.visible}
    >
      <Form
        form={form}
        name="form-filtros"
        layout="vertical"
        onKeyPress={onEnter}
        initialValues={initial}
      >
        <Scrollbars
          className="sidebar-scroll"
          style={{ width: "256px", height: "100%" }}
          autoHide
        >
          <div className="filters-content">{filters && filters()}</div>
        </Scrollbars>

        <div className="filters-actions">
          <Button type="secondary" onClick={reset}>
            Limpiar
          </Button>
          <Button type="primary" onClick={onSave}>
            Buscar
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default Filters;
