/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Button, Form, Input, Select } from "antd";
import { FiSettings } from "react-icons/fi";

import http from "./../../../services/http";
import { showSuccess } from "./../../../services/notification";
import { hasAction, actions } from "./../../../services/security";
import { required } from "./../../../services/validation";

import Header from "./../../../components/Header";

const { TextArea } = Input;
const { Option } = Select;

const ParametrosForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.PARAMETROS_VER);
  const CREAR = hasAction(actions.PARAMETROS_EDITAR);
  const EDITAR = hasAction(actions.PARAMETROS_EDITAR);
  const GUARDAR = match.params.id === "nuevo" ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === "nuevo" ? "Crear" : EDITAR ? "Editar" : "Ver",
    id: match.params.id === "nuevo" ? 0 : parseInt(match.params.id),
    loading: false,
  });
  const [grupos, setGrupos] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Configuración" },
    { id: 2, title: "Parámetros", url: "/configuracion/parametros" },
    { id: 3, title: state.title },
  ];

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const rSave =
      state.id === 0
        ? await http.post("parametros", values)
        : await http.put(`parametros/${state.id}`, values);

    if (rSave && rSave.data.code === 0) {
      history.push("/configuracion/parametros");
      showSuccess("El parámetro fue guardado exitosamente.");
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      const rData = await http.get("parametros/data");
      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        setGrupos(data.grupos);
      }

      const rForm = await http.get(`parametros/${state.id}`);
      if (rForm.data.code === 0) {
        const data = rForm.data.data;
        form.setFieldsValue(data);
      }
    }

    if (VER) {
      getForm();
    }
  }, []);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="parametro">
      <Header title={`${state.title} Parámetro`} breadcrumb={breadcrumb} />

      <Form form={form} layout="vertical" onFinish={onSave}>
        <div className="form card">
          <div className="form-icon">
            <FiSettings />
          </div>

          <Form.Item label="Grupo" name="grupo" rules={[required]}>
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              disabled={!GUARDAR}
            >
              {grupos.map((g) => (
                <Option key={g.value} value={g.value}>
                  {g.text}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Nombre" name="nombre" rules={[required]}>
            <Input maxLength={100} disabled={true} />
          </Form.Item>

          <Form.Item
            label="Descripción"
            name="descripcion"
            className="textarea"
          >
            <TextArea showCount maxLength={500} disabled={!GUARDAR} />
          </Form.Item>

          <Form.Item label="Valor" name="valor" className="textarea">
            <TextArea showCount maxLength={2000} disabled={!GUARDAR} />
          </Form.Item>

          <div className="form-actions">
            <Link to="/configuracion/parametros">
              <Button type="secondary">Volver</Button>
            </Link>
            {GUARDAR && (
              <Button type="primary" htmlType="submit" loading={state.loading}>
                Guardar
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ParametrosForm;
