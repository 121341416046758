import React, { useContext } from "react";
import { Button } from "antd";
import { FiSearch } from "react-icons/fi";
import { Context } from "./../services/context";

const FiltersButton = () => {
  const [state, setState] = useContext(Context);

  const show = () => {
    let nState = { ...state };
    nState.filters.visible = !nState.filters.visible;
    setState(nState);
  };

  return (
    <Button type="secondary" icon={<FiSearch />} onClick={show}>
      Filtros
    </Button>
  );
};

export default FiltersButton;
