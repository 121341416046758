/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Button, Form, Input, Modal, Switch, Select } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FiFileText } from 'react-icons/fi';

import http from '../../services/http';
import { showSuccess } from '../../services/notification';
import { hasAction, actions } from '../../services/security';
import { required } from '../../services/validation';
import { ContextProvider } from '../../services/context';

import Header from '../../components/Header';
import Documentos from './Documentos';

const { TextArea } = Input;
const { Option } = Select;

const DigestoForm = ({ match }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const VER = hasAction(actions.DIGESTO_VER);
  const CREAR = hasAction(actions.DIGESTO_CREAR);
  const EDITAR = hasAction(actions.DIGESTO_EDITAR);
  const GUARDAR = match.params.id === 'nuevo' ? CREAR : EDITAR;

  const [state, setState] = useState({
    title: match.params.id === 'nuevo' ? 'Crear' : EDITAR ? 'Editar' : 'Ver',
    id: match.params.id === 'nuevo' ? 0 : parseInt(match.params.id),
    loading: false,
    reload: false,
    documentos: [],
    archivos: [],
    selected: [],
    docsSelected: [],
    docIdSelected: null,
    maxFileSize: 0,
  });

  const breadcrumb = [
    { id: 1, title: 'Digesto', url: '/digesto' },
    { id: 2, title: state.title },
  ];
  const [direcciones, setDirecciones] = useState([]);

  const onSave = async (values) => {
    setState({ ...state, loading: true });

    const result =
      state.id === 0
        ? await http.post('digesto', values)
        : await http.put(`digesto/${state.id}`, values);

    if (result && result.data.code === 0) {
      const data = result.data.data;

      if (state.id === 0) {
        Modal.confirm({
          title: 'Continuar editando',
          icon: <ExclamationCircleOutlined />,
          content: '¿Desea seguir editando este registro de digesto?',
          okText: 'Confirmar',
          cancelText: 'Cancelar',
          onOk: () => {
            setState({ ...state, id: data, title: 'Editar' });
            history.push(`/digesto/${data}`);
          },
          onCancel: () => history.push('/digesto'),
        });
      } else {
        showSuccess('El registro fue guardado exitosamente.');
        history.push('/digesto');
      }
    } else {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    async function getForm() {
      let maxFileSize = 0;

      let response = await http.get(`digesto/data`);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        maxFileSize = parseInt(data.maxFileSize);
        setDirecciones(data.direcciones);
      }

      response = await http.get(`digesto/${state.id}`);
      if (response.data.code === 0) {
        const data = response.data.data;
        form.setFieldsValue(data);

        setState({
          ...state,
          maxFileSize: maxFileSize,
          documentos: data.documentos,
        });
      }
    }

    if (VER) {
      getForm();
    }
  }, [state.id, state.reload]);

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <ContextProvider value={[state, setState]}>
      <div className="digesto">
        <Header
          title={`${state.title} registro de Digesto`}
          breadcrumb={breadcrumb}
        />

        <Form form={form} layout="vertical" onFinish={onSave}>
          <div className="form card">
            <div className="form-icon">
              <FiFileText />
            </div>

            <Form.Item label="Número" name="numero" rules={[required]}>
              <Input maxLength={250} disabled={!GUARDAR} />
            </Form.Item>
            <Form.Item label="Dirección" name="direccion" rules={[required]}>
              <Select allowClear disabled={!GUARDAR}>
                {direcciones.map((g) => (
                  <Option key={g.value} value={g.value}>
                    {g.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Descripción"
              name="descripcion"
              rules={[required]}
            >
              <Input maxLength={250} disabled={!GUARDAR} />
            </Form.Item>

            <Form.Item label="Comentario" name="comentario" rules={[required]}>
              <TextArea disabled={!GUARDAR} />
            </Form.Item>

            <Form.Item
              label="¿Es sensible?"
              name="sensible"
              valuePropName="checked"
              disabled={!GUARDAR}
              rules={[required]}
            >
              <Switch checkedChildren="Si" unCheckedChildren="No" />
            </Form.Item>

            <div className="form-actions">
              <Link to="/digesto">
                <Button type="secondary">Volver</Button>
              </Link>
              {GUARDAR && (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={state.Loading}
                >
                  Guardar
                </Button>
              )}
            </div>
          </div>
        </Form>

        {state.id > 0 && (
          <React.Fragment>
            <Documentos />
          </React.Fragment>
        )}
      </div>
    </ContextProvider>
  );
};

export default DigestoForm;
