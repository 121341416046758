import React from "react";
import { useHistory } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import { FiSidebar, FiPower } from "react-icons/fi";
import { getUser } from "./../services/security";
import man from "./../assets/images/man.png";
import woman from "./../assets/images/woman.png";
import "./NavBar.scss";

const NavBar = ({ collapsed, onCollapse }) => {
  const history = useHistory();
  const user = getUser();
  const icon = user.sexo === "M" ? man : woman;

  const logout = () => {
    localStorage.clear();
    history.push("/login");
  };

  const menuUser = (
    <Menu className="navbar-actions">
      <Menu.Item key="1" onClick={logout}>
        <FiPower size="1.5em" />
        Cerrar Sesión
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="navbar">
      <div className="navbar-toggle" onClick={() => onCollapse(!collapsed)}>
        <FiSidebar />
      </div>
      <Dropdown
        overlay={menuUser}
        trigger={["click"]}
        placement="bottomRight"
        arrow
      >
        <div className="user">
          <div className="data">
            <p className="name">{user.nombre}</p>
            <p className="status">Disponible</p>
          </div>
          <img className="icon" src={icon} alt="logo" />
        </div>
      </Dropdown>
    </div>
  );
};

export default NavBar;
