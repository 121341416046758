/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { DatePicker, Form, Input, Menu, Select } from "antd";
import { FiSearch } from "react-icons/fi";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";

import { ContextProvider } from "./../../../services/context";
import http from "./../../../services/http";
import { hasAction, actions } from "./../../../services/security";

import Header from "./../../../components/Header";
import Filters from "./../../../components/Filters";
import FiltersButton from "./../../../components/FiltersButton";
import Table from "./../../../components/Table";

const { Option, OptGroup } = Select;

const Auditoria = () => {
  const history = useHistory();

  const VER = hasAction(actions.AUDITORIA_VER);

  const [state, setState] = useState({
    filters: {
      visible: false,
      desde: "",
      hasta: "",
      identificador: "",
      usuario: "",
      accion: "",
    },
    columns: [
      {
        id: 1,
        title: "Usuario",
        style: {},
        render: (item) => item.usuario,
      },
      {
        id: 2,
        title: "Acción",
        style: {},
        render: (item) => item.accion,
      },
      {
        id: 3,
        title: "Fecha",
        style: {},
        render: (item) => item.fecha,
      },
      {
        id: 4,
        title: "Hora",
        style: {},
        render: (item) => item.hora,
      },
      {
        id: 5,
        title: "Identificador",
        style: {},
        render: (item) => item.identificador,
      },
    ],
    loading: true,
    loaded: false,
    reload: true,
    list: [],
    total: 0,
    page: 1,
    size: 10,
  });
  const [modulos, setModulos] = useState([]);

  const breadcrumb = [
    { id: 1, title: "Seguridad" },
    { id: 2, title: "Auditoría", url: "/seguridad/auditoria" },
  ];

  const buttons = () => {
    return (
      <React.Fragment>
        <FiltersButton />
      </React.Fragment>
    );
  };

  const filters = () => {
    return (
      <React.Fragment>
        <Form.Item label="Desde" name="desde">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>
        <Form.Item label="Hasta" name="hasta">
          <DatePicker format="DD/MM/YYYY" placeholder="" locale={locale} />
        </Form.Item>
        <Form.Item label="Identificador" name="identificador">
          <Input />
        </Form.Item>
        <Form.Item label="Usuario" name="usuario">
          <Input />
        </Form.Item>
        <Form.Item label="Accion" name="accion">
          <Select allowClear>
            {modulos.map((m) => (
              <OptGroup key={m.id} label={m.nombre}>
                {m.acciones.map((a) => (
                  <Option key={a.id} value={a.id}>
                    {m.nombre} - {a.nombre}
                  </Option>
                ))}
              </OptGroup>
            ))}
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        <Menu.Item key="0" onClick={() => onEdit(item)}>
          <FiSearch />
          Ver
        </Menu.Item>
      </Menu>
    );
  };

  useEffect(() => {
    async function getData() {
      const rData = await http.get("auditoria/data");
      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        setModulos(data.modulos);
      }
    }

    getData();
  }, []);

  const onEdit = (item) => {
    history.push(`/seguridad/auditoria/${item.id}`);
  };

  if (!VER) {
    return <Redirect to="/" />;
  }

  return (
    <div className="perfiles page">
      <ContextProvider value={[state, setState]}>
        <Header title="Auditoría" breadcrumb={breadcrumb} buttons={buttons} />
        <Filters filters={filters} />
        <Table url="auditoria/list" menu={menu} />
      </ContextProvider>
    </div>
  );
};

export default Auditoria;
