/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Form, Select, Button } from "antd";
import { FiLogIn } from "react-icons/fi";

import { Context } from "../../services/context";
import http from "../../services/http";
import { required } from "../../services/validation";

const { Option } = Select;

const DocumentosForm = ({ item, onClose }) => {
  const [form] = Form.useForm();

  const [state, setState] = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [masterDocs, setMasterDocs] = useState([]);

  const onSave = async (values) => {
    setLoading(true);

    values.entidadId = state.id;

    const rSave = await http.post("cajadiaria/documentos", values);

    if (rSave && rSave.data.code === 0) {
      form.resetFields();
      setState({ ...state, reload: !state.reload });
      setLoading(false);
      onClose();
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function getData() {
      const rData = await http.get(`cajadiaria/documentos/data`);

      if (rData && rData.data.code === 0) {
        const data = rData.data.data;
        setMasterDocs(data.masterDocs);
      }
    }

    getData();
  }, []);

  return (
    <Form
      form={form}
      name="form-documentos"
      layout="vertical"
      onFinish={onSave}
    >
      <div className="form">
        <div className="form-icon">
          <FiLogIn />
        </div>

        <div style={{ position: "relative" }}>
          <Form.Item label="Documento" name="masterDocId" rules={[required]}>
            <Select allowClear showSearch optionFilterProp="children">
              {masterDocs.map((x) => (
                <Option key={x.value} value={x.value}>
                  {x.text}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className="form-actions">
          <Button type="secondary" onClick={onClose}>
            Volver
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Guardar
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default DocumentosForm;
