import React, { useContext, useRef, useState } from "react";
import { Dropdown, Checkbox, Menu, Button, Modal, Progress } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  FiPaperclip,
  FiSearch,
  FiEdit,
  FiDelete,
  FiChevronDown,
  FiChevronUp,
  FiMoreVertical,
} from "react-icons/fi";
import { SlideDown } from "react-slidedown";
import Viewer from "react-viewer";
import Compressor from "compressorjs";
import { FileIcon, defaultStyles } from "react-file-icon";

import { Context } from "../../services/context";
import http from "../../services/http";
import { showSuccess, showWarning } from "../../services/notification";
import { hasAction, actions } from "../../services/security";
import "./Archivo.scss";
import fileDownload from "js-file-download";

const Archivos = () => {
  const AGREGAR = hasAction(actions.LEGAJO_ARCHIVO_AGREGAR);
  const ELIMINAR = hasAction(actions.LEGAJO_ARCHIVO_ELIMINAR);
  const VER = hasAction(actions.LEGAJO_ARCHIVO_VER);

  const [state, setState] = useContext(Context);
  const [visible, setVisible] = useState(true);
  const fileRef = useRef(null);
  const [progress, setProgress] = useState();
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const images = ["jpg", "jpeg", "bmp", "gif", "png"];
  const [loading, setLoading] = useState(false);

  const documento = state.docIdSelected
    ? state.documentos.find((x) => x.id === state.docIdSelected)
    : null;

  const onAdd = () => {
    fileRef.current.click();
  };

  const onUpload = async (e) => {
    const files = e.target.files;
    if (files.length === 0) {
      return;
    }
    sessionStorage.setItem("countFileLeg", files.length);
    setProgress(5);

    for (let i = 0; i < files.length; i++) {
      if (files[i].type.includes("image")) {
        compress(files[i]);
      } else {
        upload(files[i]);
      }
    }
    e.target.value = null;
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const compress = async (file) => {
    new Compressor(file, {
      quality: 0.6,
      async success(result) {
        await upload(result);
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  const upload = async (file) => {
    const base64 = await toBase64(file);
    const data = {
      nombre: file.name,
      contenido: base64.split("base64,")[1],
      tamanio: file.size.toString(),
      documentoId: documento.id,
    };

    if (data.tamanio / 1000000 > state.maxFileSize) {
      showWarning("El archivo supera el tamaño máximo permitido");
      return;
    }

    setProgress(5);

    try {
      let countFile = sessionStorage.getItem("countFileLeg");
      countFile--;
      sessionStorage.setItem("countFileLeg", countFile);

      await http.postFile("legajo/archivos", data, setProgress);
      setProgress(null);
      if (countFile === 0) setState({ ...state, reload: !state.reload });
    } catch (error) {
      setProgress(null);
      setState({ ...state, reload: !state.reload });
    }
  };

  const onPreview = (file) => {
    setPreviewUrl(`${process.env.REACT_APP_API_URL}/archivos/${file.id}`);
    setPreviewVisible(true);
  };

  const onDownload = (file) => {
    window.open(
      `${process.env.REACT_APP_API_URL}/archivos/${file.id}`,
      "_blank"
    );
  };

  const onDownloadList = async () => {
    setLoading(true);

    let nSelected = [...state.selected];
    let ids = "";
    nSelected.map((x) => (ids += x + ","));
    ids = ids.slice(0, -1);
    const data = { ids };
    const rDownload = await http.post("archivos/list", data, true);
    setLoading(false);

    fileDownload(
      rDownload.data,
      `Archivos_${documento.masterDoc}_${Date.now()}.zip`
    );
  };

  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro que desea eliminar este archivo?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`legajo/archivos/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("El archivo fue eliminado exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  const menu = (file) => {
    return (
      <Menu className="table-actions-menu">
        {images.indexOf(file.extension) > -1 && (
          <Menu.Item key="0" onClick={() => onPreview(file)}>
            <FiSearch />
            Ver
          </Menu.Item>
        )}
        <Menu.Item key="1" onClick={() => onDownload(file)}>
          <FiEdit />
          Descargar
        </Menu.Item>
        {ELIMINAR && (
          <Menu.Item key="2" onClick={() => onDelete(file)}>
            <FiDelete />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onCheckAll = (checked) => {
    const ids = documento.archivos.map((x) => x.id);
    let nSelected = [...state.selected];

    if (checked) {
      nSelected = [...nSelected, ...ids];
      nSelected = nSelected.filter(
        (item, pos) => nSelected.indexOf(item) === pos
      );
    } else {
      nSelected = nSelected.filter((x) => !ids.includes(x));
    }

    setState({ ...state, selected: nSelected });
  };

  const onCheck = (item, checked) => {
    let nSelected = [...state.selected.filter((x) => x !== item.id)];

    if (checked) {
      nSelected.push(item.id);
    }

    setState({ ...state, selected: nSelected });
  };

  return (
    <div className="archivos form-subsection" style={{ boxShadow: "none" }}>
      <Viewer
        visible={previewVisible}
        onClose={() => {
          setPreviewVisible(false);
        }}
        images={[{ src: previewUrl, alt: "" }]}
      />

      <div
        className="form-subsection-header"
        onClick={() => setVisible(!visible)}
      >
        <div className="icon">
          <FiPaperclip />
        </div>
        <div className="content">
          <div className="title">Archivos</div>
          <div className="subtitle">{documento.archivos.length} elementos</div>
        </div>
        <div className="collapse">
          {visible ? <FiChevronUp /> : <FiChevronDown />}
        </div>
      </div>
      <SlideDown closed={!visible}>
        <div className="form-subsection-content">
          <table className="table table-small">
            <thead>
              <tr>
                <th></th>
                <th>Nombre</th>
                <th>Fecha</th>
                <th>Hora</th>
                <th>Usuario quien cargo</th>
                <th>Tamaño</th>
                <th className="col-checkbox">
                  <Checkbox onChange={(e) => onCheckAll(e.target.checked)} />
                </th>
              </tr>
            </thead>
            <tbody>
              {documento.archivos.length === 0 && (
                <tr>
                  <td className="table-empty" colSpan="6">
                    Sin resultados
                  </td>
                </tr>
              )}

              {documento.archivos.map((x) => (
                <tr key={x.id}>
                  <td className="table-actions">
                    <Dropdown
                      key={x.id}
                      overlay={() => menu(x)}
                      trigger={["click"]}
                      placement="bottomLeft"
                      arrow
                    >
                      <div>
                        <FiMoreVertical />
                      </div>
                    </Dropdown>
                  </td>

                  <td className="td-icon">
                    <div className="icon">
                      <FileIcon
                        extension={x.extension}
                        {...defaultStyles[x.extension]}
                      />
                    </div>

                    {x.nombre}
                  </td>
                  <td>{x.fecha}</td>
                  <td>{x.hora}</td>
                  <td>{x.usuario}</td>
                  <td>{x.tamanio}</td>
                  <td className="col-checkbox">
                    <Checkbox
                      checked={state.selected.indexOf(x.id) > -1}
                      onChange={(e) => onCheck(x, e.target.checked)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <br />
        {progress && (
          <div>
            <span>Subiendo...</span>
            <Progress percent={progress} />
          </div>
        )}
        <div className="actions">
          {AGREGAR && (
            <div>
              <input
                type="file"
                multiple={true}
                ref={fileRef}
                onChange={onUpload}
              />
              <Button type="primary" disabled={progress > 0} onClick={onAdd}>
                Subir
              </Button>
            </div>
          )}
          {state.selected.length > 0 && VER && (
            <Button
              type="secondary"
              className="download"
              onClick={onDownloadList}
              loading={loading}
            >
              Descargar seleccionados
            </Button>
          )}
        </div>
      </SlideDown>
    </div>
  );
};

export default Archivos;
