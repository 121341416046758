/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import { Scrollbars } from "react-custom-scrollbars";

import { validateToken } from "./../services/security";

import NavBar from "./../components/NavBar";
import SideBar from "./../components/SideBar";

import Inicio from "./Inicio/Inicio";
import AuditoriaForm from "./Seguridad/Auditoria/AuditoriaForm";
import Parametros from "./Configuracion/Parametros/Parametros";
import ParametrosForm from "./Configuracion/Parametros/ParametrosForm";
import Perfiles from "./Seguridad/Perfiles/Perfiles";
import PerfilesForm from "./Seguridad/Perfiles/PerfilesForm";
import Usuarios from "./Seguridad/Usuarios/Usuarios";
import UsuariosForm from "./Seguridad/Usuarios/UsuariosForm";
import Auditoria from "./Seguridad/Auditoria/Auditoria";
import NotFound from "./NotFound";
import MasterDoc from "./Configuracion/MasterDoc/MasterDoc";
import MasterDocForm from "./Configuracion/MasterDoc/MasterDocForm";
import ExpedienteForm from "./Expediente/ExpedienteForm";
import Expediente from "./Expediente/Expediente";
import LegajoForm from "./Legajo/LegajoForm";
import Legajo from "./Legajo/Legajo";
import DireccionesForm from "./Configuracion/Direcciones/DireccionesForm";
import Direcciones from "./Configuracion/Direcciones/Direcciones";
import CajaDiariaForm from "./CajaDiaria/CajaDiariaForm";
import CajaDiaria from "./CajaDiaria/CajaDiaria";
import SistemasForm from "./Sistemas/SistemasForm";
import Sistemas from "./Sistemas/Sistemas";
import DigestoForm from "./Digesto/DigestoForm";
import Digesto from "./Digesto/Digesto";
import RendicionesForm from "./Rendiciones/RendicionesForm";
import Rendiciones from "./Rendiciones/Rendiciones";

import "react-slidedown/lib/slidedown.css";
import "./Master.scss";

const Master = () => {
  const history = useHistory();
  const minWidth = 992;
  const [visible, setVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(window.innerWidth < minWidth);

  useEffect(() => {
    function windowResize() {
      setCollapsed(window.innerWidth < minWidth);
    }

    async function validate() {
      const validToken = await validateToken();

      if (!validToken) {
        localStorage.clear();
        history.push("/login");
      } else {
        setVisible(true);
      }
    }

    window.addEventListener("resize", windowResize);
    validate();
  }, []);

  if (!visible) {
    return <div></div>;
  }

  return (
    <div className="master">
      <SideBar collapsed={collapsed} onCollapse={setCollapsed} />
      <Scrollbars
        className="master-scroll"
        style={{ width: "100%", height: "100vh" }}
        autoHide
      >
        <main className="content">
          <NavBar collapsed={collapsed} onCollapse={setCollapsed} />
          <AnimatedSwitch
            atEnter={{ opacity: 0 }}
            atLeave={{ opacity: 0 }}
            atActive={{ opacity: 1 }}
            className="switch-wrapper"
          >
            <Route path="/expediente/:id" component={ExpedienteForm} />
            <Route path="/expediente" component={Expediente} />
            <Route path="/legajo/:id" component={LegajoForm} />
            <Route path="/legajo" component={Legajo} />
            <Route path="/caja-diaria/:id" component={CajaDiariaForm} />
            <Route path="/caja-diaria" component={CajaDiaria} />
            <Route path="/sistemas/:id" component={SistemasForm} />
            <Route path="/sistemas" component={Sistemas} />
            <Route path="/digesto/:id" component={DigestoForm} />
            <Route path="/digesto" component={Digesto} />
            <Route path="/rendiciones/:id" component={RendicionesForm} />
            <Route path="/rendiciones" component={Rendiciones} />

            {/* Configuracion */}
            <Route
              path="/configuracion/parametros/:id"
              component={ParametrosForm}
            />
            <Route path="/configuracion/parametros" component={Parametros} />
            <Route
              path="/configuracion/masterdoc/:id"
              component={MasterDocForm}
            />
            <Route path="/configuracion/masterdoc" component={MasterDoc} />
            <Route
              path="/configuracion/direcciones/:id"
              component={DireccionesForm}
            />

            <Route path="/configuracion/direcciones" component={Direcciones} />
            {/* Seguridad */}
            <Route path="/seguridad/auditoria/:id" component={AuditoriaForm} />
            <Route path="/seguridad/auditoria" component={Auditoria} />
            <Route path="/seguridad/perfiles/:id" component={PerfilesForm} />
            <Route path="/seguridad/perfiles" component={Perfiles} />
            <Route path="/seguridad/usuarios/:id" component={UsuariosForm} />
            <Route path="/seguridad/usuarios" component={Usuarios} />

            <Route path="/not-found" component={NotFound} />
            <Route path="/" exact component={Inicio} />
            <Redirect to="/not-found" />
          </AnimatedSwitch>
        </main>
      </Scrollbars>
    </div>
  );
};

export default Master;
