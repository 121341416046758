import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import CountUp from "react-countup";
import {
  FiShield,
  FiUser,
  FiClock,
  FiFileText,
  FiSettings,
  FiFolder,
} from "react-icons/fi";
import Header from "./../../components/Header";
import http from "./../../services/http";
import { hasAction, actions } from "./../../services/security";
import "./Inicio.scss";

const Inicio = () => {
  const [state, setState] = useState({
    expedientes: 0,
    legajos: 0,
    cajaDiaria: 0,
    sistemas: 0,
    digesto: 0,
    rendiciones: 0,
    parametros: 0,
    masterDoc: 0,
    direcciones: 0,
    perfiles: 0,
    usuarios: 0,
    auditoria: 0,
  });

  useEffect(() => {
    async function getData() {
      const result = await http.get("inicio");
      if (result && result.data.code === 0) {
        const data = result.data.data;
        setState(data);
      }
    }

    getData();
  }, []);

  return (
    <div className="inicio">
      <Header title="Inicio" breadcrumb={[]} buttons={() => {}} />

      <Row gutter={[32, 36]}>
        {hasAction(actions.EXPEDIENTE_VER) && (
          <Col xs={12} md={8} xl={6} xxl={4}>
            <Link to="/expediente">
              <div className="card success">
                <div className="card-icon">
                  <FiFileText />
                </div>
                <div className="card-count">
                  <CountUp end={state.expedientes} />
                </div>
                <div className="card-title">Expedientes</div>
              </div>
            </Link>
          </Col>
        )}
        {hasAction(actions.LEGAJO_VER) && (
          <Col xs={12} md={8} xl={6} xxl={4}>
            <Link to="/legajo">
              <div className="card success">
                <div className="card-icon">
                  <FiFileText />
                </div>
                <div className="card-count">
                  <CountUp end={state.legajos} />
                </div>
                <div className="card-title">Legajos</div>
              </div>
            </Link>
          </Col>
        )}
        {hasAction(actions.CAJA_DIARIA_VER) && (
          <Col xs={12} md={8} xl={6} xxl={4}>
            <Link to="/caja-diaria">
              <div className="card success">
                <div className="card-icon">
                  <FiFileText />
                </div>
                <div className="card-count">
                  <CountUp end={state.cajaDiaria} />
                </div>
                <div className="card-title">Caja Diaria</div>
              </div>
            </Link>
          </Col>
        )}
        {hasAction(actions.SISTEMAS_VER) && (
          <Col xs={12} md={8} xl={6} xxl={4}>
            <Link to="/sistemas">
              <div className="card success">
                <div className="card-icon">
                  <FiFileText />
                </div>
                <div className="card-count">
                  <CountUp end={state.sistemas} />
                </div>
                <div className="card-title">Sistemas</div>
              </div>
            </Link>
          </Col>
        )}
        {hasAction(actions.DIGESTO_VER) && (
          <Col xs={12} md={8} xl={6} xxl={4}>
            <Link to="/digesto">
              <div className="card success">
                <div className="card-icon">
                  <FiFileText />
                </div>
                <div className="card-count">
                  <CountUp end={state.digesto} />
                </div>
                <div className="card-title">Digesto</div>
              </div>
            </Link>
          </Col>
        )}
        {hasAction(actions.RENDICIONES_VER) && (
          <Col xs={12} md={8} xl={6} xxl={4}>
            <Link to="/rendiciones">
              <div className="card success">
                <div className="card-icon">
                  <FiFileText />
                </div>
                <div className="card-count">
                  <CountUp end={state.rendiciones} />
                </div>
                <div className="card-title">Rendiciones</div>
              </div>
            </Link>
          </Col>
        )}
      </Row>

      <Row gutter={[32, 36]}>
        {hasAction(actions.PARAMETROS_VER) && (
          <Col xs={12} md={8} xl={6} xxl={4}>
            <Link to="/configuracion/parametros">
              <div className="card info">
                <div className="card-icon">
                  <FiSettings />
                </div>
                <div className="card-count">
                  <CountUp end={state.parametros} />
                </div>
                <div className="card-title">Parámetros</div>
              </div>
            </Link>
          </Col>
        )}
        {hasAction(actions.MASTERDOC_VER) && (
          <Col xs={12} md={8} xl={6} xxl={4}>
            <Link to="/configuracion/masterdoc">
              <div className="card info">
                <div className="card-icon">
                  <FiSettings />
                </div>
                <div className="card-count">
                  <CountUp end={state.masterDoc} />
                </div>
                <div className="card-title">Documentos</div>
              </div>
            </Link>
          </Col>
        )}
        {hasAction(actions.DIRECCIONES_VER) && (
          <Col xs={12} md={8} xl={6} xxl={4}>
            <Link to="/configuracion/direcciones">
              <div className="card info">
                <div className="card-icon">
                  <FiFolder />
                </div>
                <div className="card-count">
                  <CountUp end={state.direcciones} />
                </div>
                <div className="card-title">Direcciones</div>
              </div>
            </Link>
          </Col>
        )}
      </Row>

      <Row gutter={[32, 36]}>
        {hasAction(actions.PERFILES_VER) && (
          <Col xs={12} md={8} xl={6} xxl={4}>
            <Link to="/seguridad/perfiles">
              <div className="card primary">
                <div className="card-icon">
                  <FiShield />
                </div>
                <div className="card-count">
                  <CountUp end={state.perfiles} />
                </div>
                <div className="card-title">Perfiles</div>
              </div>
            </Link>
          </Col>
        )}
        {hasAction(actions.USUARIOS_VER) && (
          <Col xs={12} md={8} xl={6} xxl={4}>
            <Link to="/seguridad/usuarios">
              <div className="card primary">
                <div className="card-icon">
                  <FiUser />
                </div>
                <div className="card-count">
                  <CountUp end={state.usuarios} />
                </div>
                <div className="card-title">Usuarios</div>
              </div>
            </Link>
          </Col>
        )}
        {hasAction(actions.AUDITORIA_VER) && (
          <Col xs={12} md={8} xl={6} xxl={4}>
            <Link to="/seguridad/auditoria">
              <div className="card primary">
                <div className="card-icon">
                  <FiClock />
                </div>
                <div className="card-count">
                  <CountUp end={state.auditoria} />
                </div>
                <div className="card-title">Auditoría</div>
              </div>
            </Link>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Inicio;
