import React, { useContext, useState } from "react";
import { Dropdown, Menu, Button, Modal, Checkbox } from "antd";
import { SlideDown } from "react-slidedown";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  FiChevronDown,
  FiChevronUp,
  FiLogIn,
  FiFile,
  FiMoreVertical,
  FiTrash2,
} from "react-icons/fi";
import { Context } from "../../services/context";
import http from "../../services/http";
import { showSuccess } from "../../services/notification";
import { hasAction, actions } from "../../services/security";

import DocumentosForm from "./DocumentosForm";
import Archivos from "./Archivos";
import "./Documentos.scss";

const Documentos = () => {
  const AGREGAR = hasAction(actions.RENDICIONES_DOCUMENTO_AGREGAR);
  const ELIMINAR = hasAction(actions.RENDICIONES_DOCUMENTO_ELIMINAR);
  const VER_ARCHIVO = hasAction(actions.RENDICIONES_ARCHIVO_VER);

  const [state, setState] = useContext(Context);
  const [visible, setVisible] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [formVisibleArchivos, setFormVisibleArchivos] = useState(false);

  const menu = (item) => {
    return (
      <Menu className="table-actions-menu">
        {ELIMINAR && (
          <Menu.Item key="2" onClick={() => onDelete(item)}>
            <FiTrash2 />
            Eliminar
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const onAdd = () => {
    setFormVisible(true);
  };

  const onArchivos = (docId) => {
    setState({ ...state, docIdSelected: docId, selected: [] });
    setFormVisibleArchivos(true);
  };

  const onCheckAll = (checked) => {
    const ids = state.documentos.map((x) => x.id);
    let nSelected = [...state.docsSelected];

    if (checked) {
      nSelected = [...nSelected, ...ids];
      nSelected = nSelected.filter(
        (item, pos) => nSelected.indexOf(item) === pos
      );
    } else {
      nSelected = nSelected.filter((x) => !ids.includes(x));
    }

    setState({ ...state, docsSelected: nSelected });
  };

  const onCheck = (item, checked) => {
    let nSelected = [...state.docsSelected.filter((x) => x !== item.id)];

    if (checked) {
      nSelected.push(item.id);
    }

    setState({ ...state, docsSelected: nSelected });
  };
  const onDownloadList = () => {
    let nSelected = [...state.docsSelected];
    let ids = "";
    nSelected.map((x) => (ids += x + ","));
    ids = ids.slice(0, -1);
    window.open(
      `${process.env.REACT_APP_API_URL}/archivos/listExpedientes/${ids}`,
      "_blank"
    );
  };
  const onDelete = (item) => {
    Modal.confirm({
      title: "Confirmar eliminación",
      icon: <ExclamationCircleOutlined />,
      content:
        "¿Esta seguro que desea eliminar este documento, si tiene archivos los mismos seran eliminados?",
      okText: "Confirmar",
      cancelText: "Cancelar",
      onOk: async () => {
        const rDelete = await http.delete(`rendiciones/documentos/${item.id}`);
        if (rDelete && rDelete.data.code === 0) {
          showSuccess("El documento fue eliminado exitosamente.");
          setState({ ...state, reload: !state.reload });
        }
      },
    });
  };

  return (
    <div className="documentos form-subsection">
      <div
        className="form-subsection-header"
        onClick={() => setVisible(!visible)}
      >
        <div className="icon">
          <FiLogIn />
        </div>
        <div className="content">
          <div className="title">Documentos</div>
          <div className="subtitle">{state.documentos.length} elementos</div>
        </div>
        <div className="collapse">
          {visible ? <FiChevronUp /> : <FiChevronDown />}
        </div>
      </div>

      <SlideDown closed={!visible}>
        <div className="form-subsection-content">
          <table className="table table-small">
            <thead>
              <tr>
                <th></th>
                <th>Documento</th>
                <th>Fecha</th>
                <th>Cantidad de Archivos</th>
                {VER_ARCHIVO && <th>Archivos</th>}
                {VER_ARCHIVO && (
                  <th className="col-checkbox">
                    <Checkbox onChange={(e) => onCheckAll(e.target.checked)} />
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {state.documentos.length === 0 && (
                <tr>
                  <td className="table-empty" colSpan="6">
                    Sin resultados
                  </td>
                </tr>
              )}

              {state.documentos.map((x) => (
                <tr key={x.id}>
                  <td className="table-actions">
                    <Dropdown
                      key={x.id}
                      overlay={() => menu(x)}
                      trigger={["click"]}
                      placement="bottomLeft"
                      arrow
                    >
                      <div>
                        <FiMoreVertical />
                      </div>
                    </Dropdown>
                  </td>
                  <td>{x.masterDoc}</td>
                  <td>{x.creacion}</td>
                  <td>{x.archivos.length}</td>
                  {VER_ARCHIVO && (
                    <td>
                      <div className="icon">
                        <FiFile
                          title="Archivos"
                          size="30"
                          onClick={() => onArchivos(x.id)}
                        />
                      </div>
                    </td>
                  )}
                  {VER_ARCHIVO && (
                    <td className="col-checkbox">
                      <Checkbox
                        checked={state.docsSelected.indexOf(x.id) > -1}
                        onChange={(e) => onCheck(x, e.target.checked)}
                      />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="actions">
          {AGREGAR && (
            <Button type="primary" onClick={onAdd}>
              Agregar
            </Button>
          )}
          {state.docsSelected.length > 0 && VER_ARCHIVO && (
            <Button
              type="secondary"
              className="download"
              onClick={onDownloadList}
            >
              Descargar seleccionados
            </Button>
          )}
        </div>
      </SlideDown>

      <Modal
        visible={formVisible}
        onCancel={() => setFormVisible(false)}
        footer={null}
        maskClosable={false}
        keyboard={false}
        destroyOnClose={true}
      >
        <DocumentosForm onClose={() => setFormVisible(false)} />
      </Modal>

      <Modal
        visible={formVisibleArchivos}
        onCancel={() => setFormVisibleArchivos(false)}
        footer={null}
        maskClosable={false}
        keyboard={false}
        destroyOnClose={true}
        width={"60%"}
      >
        <Archivos />
      </Modal>
    </div>
  );
};

export default Documentos;
